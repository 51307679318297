@import "../../styles/theme";

.scheduleTournamentView {
    section {
        h2 {
            color: $c-header2;
            font-size: 1.4rem;
            font-weight: 700;
        }
    }
    .scheduleTournament {
        .tcInputContainer {
            margin-bottom: 40px;
            .tcInput {
                width: 100%;
            }
        }
        ul {
            margin-bottom: 40px;
            //padding: 15px 10px;
            background-color: $c-bg2;
            li {
                border-left: 1px solid $c-border-trans;
                border-right: 1px solid $c-border-trans;
                border-bottom: 1px solid $c-border-trans;
                &:first-of-type {
                    border-top: 1px solid $c-border-trans;
                }
                a {
                    width: 100%;
                    height: 100%;
                    padding: 15px 10px;
                    display: block;
                    color: $c-text2;
                    font-size: 1rem;
                    &:hover {
                        color: $c-theme1;
                    }
                }
            }
        }
        .tcButton {
            width: 100%;
            display: block;
            text-align: center;
            border-color: $c-theme1;
        }
    }
}
