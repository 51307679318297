@import "../../styles/theme";

.scheduleView {
    .scheduleList {
        ul {
            margin-bottom: 40px;
            background-color: $c-bg2;
            border: 1px solid $c-border-trans;
            li {
                a,
                span {
                    width: 100%;
                    padding: 15px 10px;
                    display: block;

                    color: $c-text2;
                    font-size: 1rem;
                }
                a {
                    &:hover {
                        color: $c-theme1;
                    }
                }
                border-bottom: 1px solid $c-border-trans;
                &:last-of-type {
                    margin: 0;
                    border: none;
                }
            }
        }
    }
}
