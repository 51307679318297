@import "./theme";

.playerView,
.matchView {
    section.matchPointsModel {
        cursor: pointer;
    }

    section {
        h2 {
            svg {
                margin: 0 0 0 5px;
                height: 1rem;
                fill: $c-text1;
                transform: rotate(-90deg);
            }
        }
        &:last-of-type {
            padding-bottom: 0;
        }
        .tableContainer {
            margin-bottom: 40px;
            &:last-of-type {
                margin-bottom: 0;
            }
            .tableHeader,
            .tableRow {
                padding: 10px;
            }
        }
    }

    section.hidden {
        h2 {
            svg {
                transform: rotate(90deg);
            }
        }
        .tableContainer {
            display: none;
        }
    }

    section.tcMainHeader {
        p {
            padding-bottom: 20px;
            color: $c-text2;
            font-size: 1rem;
            font-weight: 400;
        }
    }
    section.matchFilter {
        .filterOptions {
            .filterGroups {
                padding-bottom: 20px;
                display: flex;
                justify-content: space-between;
                &:last-of-type {
                    padding-bottom: 0;
                }
                .filterGroup,
                .tcButton,
                .tcSelect {
                    width: calc(50% - 20px);
                }
                .filterGroup {
                    .tcCheckboxContainer {
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }
    section.matchPointsModel {
        .tableContainer {
            .tableCell {
                width: calc(27.5% - 20px);
                &:first-of-type {
                    width: calc(40% - 20px);
                    span {
                        color: $c-header2;
                    }
                }
                .percentage {
                    font-size: 0.75rem;
                    color: $c-theme2;

                    margin-top: -5px;
                    margin-left: 5px;
                    display: block;
                }
            }
        }
    }
    section.playerNotes {
        .notesContainer {
            width: 100%;
            flex-direction: column;
            display: flex;
            .tcTextarea {
                margin-bottom: 20px;
            }
            .tcButton {
                &.unsaved {
                    background-color: rgba($c-theme2, 0.1);
                }
            }
        }
    }
    section.historyResults {
        .resultsHeader {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            .tcButton {
                width: calc(50% - 20px);
                &.enabled,
                &:hover {
                    color: $c-text1;
                    border-color: $c-theme2;
                    background-color: $c-bg2;
                }
            }
        }
        .results {
            width: 100%;
            display: none;
            flex-direction: column;
            &.enabled {
                display: flex;
            }
            .tableHeader {
                .country {
                    margin: 0 auto 0 0;
                }
            }
            .tableRow {
                .score.lost {
                    span {
                        color: rgb(255, 51, 51, 95%);
                    }
                }
                > div {
                    height: 100%;
                    span,
                    a {
                        display: block;
                        color: $c-text2;
                        font-size: 0.9rem;
                        font-weight: 400;
                    }
                    a:hover {
                        color: $c-theme1;
                    }
                }
                .date {
                    width: 30%;
                }
                .surface {
                    width: 25px;
                    margin: auto 5px auto 0;
                    .surfaceImg {
                        width: 15px;
                        height: 15px;
                        background-color: $c-theme1;
                        &.clay {
                            background-color: #ff7500;
                        }
                        &.grass {
                            background-color: #74ae30;
                        }
                        &.hard {
                            background-color: #788575;
                        }
                        &.indoor {
                            background-color: #618cb1;
                        }
                    }
                }
                .opponent {
                    width: calc(40% - 25px);
                }
                .score {
                    width: 30%;
                }
            }
        }
    }
}
