@import "theme";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    font-family: "Nunito sans", verdana, sans-serif;
    font-size: 1rem;
    font-weight: normal;
    text-decoration: none;
    color: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    height: 100%;
}

body {
    height: 100%;
    display: flex; // TODO
    overflow: hidden;
}
#root {
    max-width: 100%;
    flex: 1;
    display: flex; // TODO
    flex-direction: row; // TODO
}

button {
    background-color: transparent;
    border: none;
    box-shadow: none;
}
li {
    list-style: none;
}
ul,
ol {
    margin: 0;
    padding: 0;
}
.tcNav {
    background-color: $c-bg2;

    width: 100%;
    height: 57px;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;

    border-top: 2px solid $c-border-trans;
    z-index: 1;
    & + .tcMain {
        padding-bottom: calc(57px + 20px);
    }
    .navItem {
        width: 100%;
        height: 100%;
        a {
            display: block;
            width: 100%;
            height: 100%;
            padding: 12.5px 10px;
            &.selected svg {
                fill: $c-theme1;
                stroke: $c-theme1;
            }
            svg {
                width: auto;
                height: 100%;
                margin: 0 auto;
                display: block;
                fill: $c-text2;
                stroke: $c-text2;
            }
        }
        &#settingsNav {
            svg {
                fill: none;
            }
        }
    }
}
.tcMain {
    width: 100%;
    padding: 20px;
    background-color: $c-bg1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}
.tcMainHeader {
    margin-bottom: 40px !important;
    padding-bottom: 5px !important;
    h1 {
        color: $c-header1;
        font-size: 1.8rem;
        font-weight: 700;
        // &::after {
        //     content: "";
        //     width: 100%;
        //     height: 3px;
        //     margin-top: 10px;
        //     display: block;
        //     background-color: $c-theme1;
        // }
    }
    border-bottom: 3px solid $c-theme1;
}

.tcLoadingScreen,
.tcErrorScreen {
    margin: auto 0;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    span {
        font-size: 1rem;
        font-weight: 600;
        color: $c-text2;
        text-align: center;
    }
}

.tcButton {
    padding: 7.5px 15px;
    border: 1px solid $c-border-trans;
    color: $c-text2;
    cursor: pointer;
    outline: none;
    &.blue {
        &:hover:enabled {
            border-color: $c-theme2;
        }
    }
    &:active:enabled {
        background-color: rgba(255, 255, 255, 0.1);
    }
    &:hover:enabled {
        border-color: $c-theme1;
        color: $c-text1;
    }
    &:disabled {
        cursor: not-allowed;
        color: $c-text3;
        background-color: $c-bg2;
    }
}

.tcSelect {
    padding: 7.5px 15px;
    border: 1px solid $c-border-trans;
    color: $c-text2;
    cursor: pointer;
    outline: none;
    background-color: transparent;

    option {
        background: $c-bg1;
        border: none;
    }
    &:hover:enabled {
        border-color: $c-theme1;
        color: $c-text1;
    }
    &:disabled {
        cursor: not-allowed;
        color: $c-text3;
        background-color: $c-bg2;
    }
}
.tcTextarea {
    padding: 7.5px 15px;
    border: 1px solid $c-border-trans;
    color: $c-text2;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    resize: none;
    &.blue {
        &:focus:enabled {
            border-color: $c-theme2;
        }
        &:hover:enabled {
            border-color: $c-theme2;
        }
    }
    &:focus:enabled {
        border-color: $c-theme1;
        color: $c-text1;
    }
    &:hover:enabled {
        border-color: $c-theme1;
    }
    &:disabled {
        cursor: not-allowed;
        color: $c-text3;
        background-color: $c-bg2;
    }
}
.tcInputContainer {
    .tcLabel {
    }
    .tcInput {
        padding: 7.5px 15px;
        border: 1px solid $c-border-trans;
        color: $c-text2;
        cursor: pointer;
        outline: none;
        background-color: transparent;
        &:focus:enabled {
            border-color: $c-theme1;
            color: $c-text1;
        }
        &:hover:enabled {
            border-color: $c-theme1;
        }
        &:disabled {
            cursor: not-allowed;
            color: $c-text3;
            background-color: $c-bg2;
        }
    }
}

section.tcSection {
    padding-bottom: 20px;
    h2 {
        padding-bottom: 20px;
        color: $c-header2;
        font-size: 1.2rem;
        font-weight: 700;
        &::after {
            content: "";
            width: 200px;
            height: 4px;
            margin-top: 5px;
            background-color: $c-theme1;
            display: block;
        }
    }
}

input[type="checkbox"].tcCheckbox {
    position: absolute;
    opacity: 0;

    & + label {
        display: flex;
        position: relative;
        cursor: pointer;

        font-weight: 600;
        line-height: 1.2rem;
        color: $c-text1;
    }

    & + label:before {
        content: "";
        width: 22px;
        height: 22px;
        margin: auto 10px auto 0;

        border: 1px solid $c-border-trans;
        border-radius: 1px;
        box-sizing: border-box;
    }

    &:hover + label:after {
        content: "";
        width: 22px;
        height: 22px;
        position: absolute;
        background-color: $c-border-trans;
    }

    &:checked + label:after {
        content: "";
        width: 22px;
        height: 22px;
        position: absolute;
        background-color: $c-theme1;
    }
}

.tableContainer {
    &:last-of-type {
        margin-bottom: 0;
    }
    .tableHeader,
    .tableRow {
        display: flex;
        justify-content: space-between;
    }
    .tableHeader {
        background-color: $c-bg2;
        border-bottom: 2px solid $c-theme2;
        span,
        a {
            color: $c-header2;
            font-weight: 700;
            font-size: 0.9rem;
        }
    }
    .tableRow {
        &:nth-of-type(odd) {
            background-color: $c-bg2;
        }
        span,
        a {
            color: $c-text1;
            font-size: 0.9rem;
            font-weight: 400;
        }
    }
    .tableCell {
        display: flex;
        position: relative;
    }
}
