@import "../../styles/theme";

.matchView {
    section.matchModel {
        .modelContainer {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            .oddsContainer {
                span {
                    color: $c-header2;
                    font-weight: 700;
                    font-size: 0.9rem;
                    padding-bottom: 10px;
                    display: block;
                }
                .tcInput {
                    width: 100%;
                }
                width: calc(50% - 20px);
            }
        }
        .optionsContainer {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            .tcSelect {
                width: calc(50% - 20px);
            }
        }
        .tcButton {
            width: 100%;
        }
        .betContainer {
            padding-top: 20px;
            p {
                padding-bottom: 2.5px;
                color: $c-text2;
            }
            .betInfo {
                font-weight: 400;
                font-size: 0.9rem;
            }
            .recommendedBet {
                font-weight: 700;
                font-size: 1.05rem;
                color: $c-theme1;
            }
        }
    }
    section.playerNotes {
        .notesHeader {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            .switchBttn {
                width: calc(50% - 20px);
                &.enabled {
                    color: $c-text1;
                    border-color: $c-theme2;
                    background-color: $c-bg2;
                }
            }
        }
        .notesContainer {
            display: none;
            &.enabled {
                display: flex;
            }
        }
    }
}
