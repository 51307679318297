@import "../../styles/theme";
.adminView {
    section {
        padding-bottom: 40px;
    }
    .eventsLog {
        .eventLog {
            max-height: 300px;
            overflow-y: auto;
            padding: 10px;
            background-color: $c-bg2;
            border: 1px solid $c-border-trans;
            .eventLogItem {
                display: flex;
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid $c-border-trans;
                &:last-of-type {
                    padding: 0;
                    margin: 0;
                    border: none;
                }
                span {
                    display: inline-block;
                    font-size: 0.8rem;
                    color: $c-text2;
                }
                .noResults,
                .error {
                    margin: 0 auto;
                }
                .error {
                    color: $c-error;
                }
                .datetime {
                    min-width: 50px;
                }
                .type {
                    min-width: 75px;
                }
                .status {
                    min-width: 80px;
                    &.AWAITING {
                        color: $c-theme2;
                    }
                    &.ERROR {
                        color: $c-error;
                    }
                    &.WORKING {
                        color: $c-theme2;
                    }
                    &.DONE {
                        color: $c-success;
                    }
                }
                .statusInfo {
                    word-wrap: normal;
                }
            }
        }
    }
    .actionsFilter {
        .tcSelect {
            width: calc(50% - 20px);
        }
        .selectInputs {
            display: flex;
            justify-content: space-between;
        }
    }
    .actionsMisc {
        .error {
            color: $c-error;
            padding-bottom: 5px;
            display: block;
        }
        .actionsButtons {
            .tcButton {
                width: calc(33% - 20px);
            }
            display: flex;
            justify-content: space-between;
        }
    }
    .actionTourns {
        .actionTournsList {
            background-color: $c-bg2;
            border: 1px solid $c-border-trans;
            .tournsListItem {
                display: flex;
                border-bottom: 1px solid $c-border-trans;
                &:last-of-type {
                    padding: 0;
                    margin: 0;
                    border: none;
                }
                span,
                a {
                    display: inline-block;
                    font-size: 1rem;
                    color: $c-text2;
                    align-self: center;
                }
                a {
                    height: 100%;
                    flex: 1;
                    padding: 15px 10px;
                }
                .noResults,
                .error {
                    margin: 0 auto;
                    padding: 15px 10px;
                }
                .error {
                    color: $c-error;
                }
                .tournName {
                    &:hover {
                        color: $c-theme1;
                    }
                }
                .statsCounter {
                    margin-left: auto;
                    padding: 0 10px;
                    color: $c-theme1;
                }
                .tournButton {
                    padding-right: 10px;
                    .tcButton {
                        padding: 5px 10px;
                        &:disabled {
                            border-color: $c-theme1 !important;
                            cursor: not-allowed;
                            color: $c-text3;
                            background-color: $c-bg2;
                        }
                    }
                }
            }
        }
    }
}
