@import "../../styles/theme";

.playersView {
    .tcErrorScreen span {
        font-weight: 400;
        font-size: 1rem;
    }
    .search {
        form {
            .tcInputContainer {
                .tcInput {
                    width: 100%;
                }
                margin-bottom: 20px;
            }
            .tcButton {
                width: 100%;
            }
        }
    }
    .results {
        .result {
            a {
                width: 100%;
                height: 100%;
                padding: 10px;
                display: flex;
                span {
                    color: $c-text2;
                }
                .gender {
                    width: 25px;
                    margin: auto 5px auto 0;
                    .genderImg {
                        width: 15px;
                        height: 15px;
                        background-color: $c-theme1;
                        &.Female {
                            background-color: #773dbd;
                        }
                        &.Male {
                            background-color: #00aff0;
                        }
                        &.Unknown {
                            background-color: $c-text2;
                        }
                    }
                }
                .name {
                }
                .country {
                }
            }
        }
    }
}
