@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700,800");

$c-bg1: rgb(24, 24, 24);
$c-bg2: rgb(32, 32, 32);
$c-border-trans: rgba(255, 255, 255, 0.2);
$c-border: rgb(30, 30, 30);

$c-header1: rgb(255, 255, 255);
$c-header2: rgb(210, 210, 210);
$c-text1: rgb(255, 255, 255);
$c-text2: rgb(180, 180, 180);
$c-text3: rgb(114, 114, 114);

$c-theme1: rgba(255, 25, 111);
$c-theme2: rgb(0, 176, 244);
$c-error: rgba(255, 51, 51, 1);
$c-success: rgba(92, 184, 92, 1);
