@import "../../styles/theme";

.loginView {
    section {
        display: flex;
        flex: 1;
        .loginContainer {
            width: 100%;
            max-width: 400px;
            margin: auto;
            text-align: center;
            h1 {
                margin-bottom: 40px;
                font-size: 1.8rem;
                font-weight: 700;
                color: $c-header1;
            }
            .tcInput {
                width: 100%;
                margin-bottom: 20px;
            }
            .tcButton {
                width: 100%;
                max-width: 50%;
                margin: 0 auto;
                display: block;
            }
        }
    }
}
